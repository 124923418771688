$auth-header-height: 50px;
$breakpoint-sm: 768px;

.auth-header {
  background-color: $default-info;
  height: $auth-header-height;
  line-height: $auth-header-height;
  color: white;
  font-weight: 600;
  padding-left: 24px;
}

.auth-form-wrapper,
.reset-password-form-wrapper {
  position: absolute;
  top: calc(50% - #{$auth-header-height});
  transform: translateY(-50%);
}
.mw500 {
  min-width: 500px;
}
.mw300 {
  min-width: 300px;
}
.mw600 {
  min-width: 600px;
}

.reset-password-form-wrapper {
  min-width: 422px;

  .form-label {
    font-size: 12px !important;
  }
}

.form-header {
  margin-bottom: 16px;
  p {
    font-weight: bold;
    margin: 0;
  }
}

#loader {
  transition: all 0.3s ease-in-out;
  opacity: 1;
  visibility: visible;
  position: fixed;
  height: 100vh;
  width: 100%;
  background: #fff;
  z-index: 90000;
}

#loader.fadeOut {
  opacity: 0;
  visibility: hidden;
}

.spinner {
  width: 40px;
  height: 40px;
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

.login-page {
  position: relative;

  .login-form {
    width: 30%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
  }
}

.content_page {
  padding: 2rem 9rem 2rem 9rem;

  .title {
    margin-top: 1rem;
    text-align: center;
  }

  .description {
    background: #f2f5ff;
    padding: 20px;
  }

  .content_form {
    margin-top: 2rem;
    border: 1px solid rgba(0, 0, 0, 0.0625);
    padding: 30px;
  }
  .action_form {
    margin-top: 2rem;
  }
}

.sidebar-menu {
  .active {
    color: #07a192 !important;
  }
}

.page-container,
.main-content {
  min-height: 100vh;
  background-color: white !important;
}

.font-18 {
  font-size: 18px;
}

.w-35p {
  width: 35%;
}

.w-25p {
  width: 25%;
}

.w-95p {
  width: 95%;
}

.d-unset {
  display: unset;
}
.d-initial {
  display: initial;
}
.employee_page {
  .search_form {
    border: 1px solid rgba(0, 0, 0, 0.0625);
    padding: 1.5rem 0 1.5rem 1.5rem;
  }

  .employee-status-label {
    min-width: 98px;
  }

  .employees-table {
    &__employee_number {
      color: #5555f3;
    }

    &__items-center {
      tr {
        th,
        td {
          text-align: center;
        }
      }
    }

    label {
      display: unset;
    }
  }
}

.ffs_page {
  .employee-status-label {
    min-width: 98px;
  }
}

.check-all {
  margin-bottom: -5px;
  margin-top: 0px;
  align-items: center;
  display: flex;
}

.detail-center {
  max-width: 768px;
  margin: 0 auto;

  &__label-bg {
    background-color: #e5e5e5;
  }

  &__content-bg {
    background-color: #f2f5ff;
    padding-left: 50px;
  }
  &__content-position {
    position: relative;
    p {
      position: absolute;
      transform: translate(0%, 50%);
    }
  }
}
.font-size-20 {
  font-size: 20px;
}
.padding-left-12 {
  padding-left: 12px;
}
.padding-right-12 {
  padding-right: 12px;
}
.padding-tb2-a-half-l3-r-7 {
  padding: 2.5rem 7rem 2.5rem 3rem;
}
.btn-primary {
  color: white;
  &:hover {
    color: white;
  }
  &:visited {
    color: white;
  }
}
.bg-grey {
  background-color: #e9ecef;
  &__500 {
    background-color: #b9c2d0 !important;
  }
}
.padding-12 {
  padding: 12px;
}
.margin-top-30 {
  margin-top: 30px;
}
.margin-bot-30 {
  margin-bottom: 30px;
}
hr.dashed {
  background-color: inherit;
  border-top: 3px dashed #8c8b8b;
}
.margin-left-10 {
  margin-left: 10px;
}
.mr-70 {
  margin-right: 70px;
}
.padding-left-12 {
  padding-left: 12px;
}
.padding-right-12 {
  padding-right: 12px;
}
.font-size-17 {
  font-size: 17px;
}
.padding-left-12 {
  padding-left: 12px;
}
.padding-right-12 {
  padding-right: 12px;
}
.fixed {
  position: fixed;
}
.top-20 {
  top: 20%;
}
.top-60 {
  top: 60%;
}
.top-80 {
  top: 80%;
}
.left-50 {
  left: 50%;
}
.transform-center {
  transform: translate(-50%, -50%);
}
.no-border {
  border: none;
}
.margin-0 {
  margin: 0;
}
form textarea.ip-address {
  min-height: 150px !important;
}
.bg-ghost-white {
  background-color: #f4f5f7;
}
.margin-0 {
  margin: 0;
}

.sidebar-logo {
  padding: 1.5px 20px;
}

.btn-primary {
  &:visited,
  &:focus,
  &:active {
    color: white;
  }
}

.btn-indigo {
  background-color: #6558f5;
}

.mt-300 {
  margin-top: 300px;
}

.text-overflow-ellipsis {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &__width-60-ch {
    max-width: 60ch;
  }

  &__width-20-ch {
    max-width: 20ch;
  }
}

.cursor-inherit {
  cursor: inherit;
}

.px-70 {
  padding-right: 70px !important;
  padding-left: 70px !important;
}

.table-ffs_requests {
  tr {
    th,
    td {
      text-align: center;
    }
  }
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: grid;
  list-style: none;
  padding: 0;
  position: absolute;
  margin-top: 30px;
  width: 295px;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  margin-left: 0px;
}

.fs-inherit {
  font-size: inherit;
}

.border-none {
  border: none;
}
.input-group-select2 {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100%;
  .select2-selection--single {
    border-color: #f2f3f5 !important;
    height: 30px !important;
  }
}
.select2-selection--single {
  border: 1px solid #ced4da !important;
  height: 34px !important;
}
.employee-btn-action {
  display: inline-block !important;
  width: 200px;
  padding: 10px;
}
.employee-btn-edit {
  margin: 30px 30%;
  width: 60%;
}
#form_update_employee {
  .detail-center__content-bg {
    padding-left: 25px !important;
  }
}

#tree {
  label {
    display: block;
    padding-right: 10px;
    padding-left: 22px;
    text-indent: -22px;
  }

  input[type="checkbox"]:checked.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  input {
    vertical-align: middle;
  }

  label span {
    vertical-align: middle;
    margin-left: 1%;
  }
}

.text-break-spaces {
  white-space: break-spaces !important;
}

.mg-l-10p {
  margin-left: 10% !important;
}
.modal-fit-content {
  min-width: 800px;
  width: fit-content;
  margin: auto;
}
#import-employee-by-id-info,
#import-ffs-request-info {
  margin-block-start: 150px;
  .content-import-data-failed {
    max-width: 100%;
    max-height: 300px;
    margin-inline-start: 100px;
    margin-inline-end: 100px;
    padding-inline-start: 10px !important;
    p {
      text-align: left;
    }
  }
  .btn-close-modal {
    margin-inline-end: 10px;
    border-color: #ced4da;
    border-radius: 0.35rem;
    background: #d0d0d0;
  }
  .btn-close-modal:hover {
    background-color: #e7e7e7;
  }
  .modal-fit-content {
    border-radius: 0.3rem;
    box-shadow: 0 0 5rem rgba(0, 0, 0, 0.25);
    border: none;
  }
}
.main-content {
  position: relative;
  margin-bottom: 50px;
}

.main-content-no-sidebar {
  position: relative;
  margin-top: 50px;
}
.auth_screen {
  .footer {
    width: 100% !important;
  }
}
.footer {
  position: fixed;
  bottom: 0;
  width: 80%;
  padding: 1rem 3rem;
  background-color: #ffffff;
}
.copyright {
  text-align: right;
  a {
    color: #72777a;
  }
  .link-privacy {
    padding-inline-start: 20px;
    padding-inline-end: 20px;
  }
  a:hover {
    color: rgb(199, 160, 98);
    background-color: transparent;
    text-decoration: underline;
  }
  a:visited {
    color: rgb(59, 63, 75);
    background-color: transparent;
    text-decoration: none;
  }
  a:active {
    color: yellow;
    background-color: transparent;
    text-decoration: underline;
  }
}

.right-corner {
  right: 0 !important;
}

.sidebar .sidebar-inner .sidebar-menu {
  .button_menu {
    width: 150px;
    border: none;
    outline: none;
    background-color: transparent;
    a {
      width: 100%;
    }
    span {
      color: white;
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1439px) {
  .sidebar .sidebar-inner .sidebar-menu {
    li {
      button {
        display: none;
      }
    }
  }
  .sidebar .sidebar-inner .sidebar-menu:hover {
    li {
      button {
        display: inline-block;
      }
    }
  }
}

.modal-fit-content {
  min-width: 800px;
  width: fit-content;
  margin: auto;
}
#import-employee-by-id-info,
#import-ffs-request-info {
  margin-block-start: 150px;
  .content-import-data-failed {
    max-width: 100%;
    max-height: 300px;
    margin-inline-start: 100px;
    margin-inline-end: 100px;
    padding-inline-start: 10px !important;
    p {
      text-align: left;
    }
  }
  .btn-close-modal-employee {
    margin-inline-end: 10px;
    border-color: #ced4da;
    border-radius: 0.35rem;
  }
}

#loader-ffs {
  transition: all 0.3s ease-in-out;
  opacity: 1;
  visibility: visible;
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 90000;
}
/*Hidden class for adding and removing*/
.lds-dual-ring.hidden {
  display: none;
}

/*Add an overlay to the entire page blocking any further presses to buttons or other elements.*/
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;
  opacity: 1;
  transition: all 0.5s;
}

/*Spinner Styles*/
.lds-dual-ring {
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 5% auto;
  border-radius: 50%;
  border: 6px solid rgb(13, 13, 100);
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.contract-btn-me {
  button {
    width: 100%;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }
}
.contract-me-content {
  margin-bottom: auto;
}
.contract-label-bg {
  padding-bottom: 20px;
}

#communication-tool {
  .m-0-auto {
    margin: 0 auto;
  }
  .row {
    padding-top: 60px;
  }
}

.mw-25p {
  max-width: 25%;
}

.mtb-50 {
  margin-bottom: 100px;
}

#js--checkboxs-communication-tool {
  justify-content: space-between;
  flex-direction: column;
  right: 0;
  position: absolute;
  display: flex;
}

.container.container-renew-contract .row:nth-child(7) {
  margin-top: 50px;
  border-top: 1px solid gray;
}

.height-150-px {
  height: 150px;
}

#communication-tool-quantity-first {
  line-height: initial;
}

#corporate-profile-detail {
  max-width: $breakpoint-sm;
  margin: 0 auto;

  .corporate-profile {
    &__title {
      text-align: center;
      background-color: #fdf1cc;
      padding-bottom: 8px;
      font-weight: 600;

      p {
        padding: 0;
        margin: 0;
        line-height: 22px;
      }
    }

    &__content {
      p {
        padding: 0;
        margin: 0;
        line-height: 22px;
      }
    }
  }

  .text-bold {
    font-weight: 600;
  }

  .line-height-40 {
    line-height: 40px;
  }

  .dashed {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed $border-color;
  }
  .input-switch {
    width: 4em;
    height: 80%;
    margin: 1px;
  }
}
.lh {
  &-26 {
    line-height: 26px !important;
  }
}
.ps {
  &-96 {
    padding-left: 96px !important;
  }
  &-81 {
    padding-left: 81px !important;
  }
}
.fs {
  &-15 {
    font-size: 15px;
  }
}

.mb {
  &-0 {
    margin-top: 0;
  }
}
.top-information {
  position: relative; }
.top-information-control {
  position: absolute;
  top: 2.5rem;
  right: 3rem;
  width: 2rem;
  height: 2rem; }
.top-information-control img {
  width: 2rem;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  transition: all 0.3s; }
.top-information-control.active img {
  -webkit-transform: rotate(0);
  transform: rotate(0); }
.top-information-list {
  list-style: none;
  padding: 0;
  border-top: 1px solid rgba(206, 212, 218, 0.3); }
.top-information-list li {
  display: flex;
  width: 100%;
  padding: 1rem 0.75rem;
  border-bottom: 1px solid rgba(206, 212, 218, 0.3); }
.top-information-date {
  margin: 0 1.5rem 0 0.25rem;
  color: #a1a6ab; }
.top-information-text a {
  text-decoration:underline;
  color: #4a5261; }

