div.billing-pdf-template {
  color: #000000;
  table {
    border: 2px solid #212529 !important;
    th {
      padding: 10px;
      border-right: 1px solid #000000 !important;
    }
    td {
      padding: 5px;
      border-top: 1px solid #000000  !important;
      border-right: 1px solid #000000  !important;
    }
  }
  .billing-info {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
  }
  .invoice-note {
    border: 1px solid #000000 !important;
    min-height: 100px;
  }
}
